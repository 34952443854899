/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/

/* @import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@100;300;400;700&family=Roboto:wght@100;300;400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@100;300;400;700&family=Readex+Pro:wght@200;300;400;700&display=swap");

html,
body,
#root,
.app {
  /* height: 100%; */
  width: 100%;
  /* font-family: 'Alegreya Sans SC', sans-serif; */
  /* font-family: "Roboto", sans-serif; */

  /* font-family: 'Alegreya Sans SC', sans-serif; */
  font-family: "Readex Pro", sans-serif;

  /* background: linear-gradient(160deg, #ffffff 0%, #a0aace 100%);
  background-attachment: fixed; */
}

/** Scollbar */

/* Vertical scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Vertical track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Vertical handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Vertical handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 10px;
}

/* Horizontal track */
::-webkit-scrollbar-track:horizontal {
  background: #f1f1f1;
}

/* Horizontal handle */
::-webkit-scrollbar-thumb:horizontal {
  background: #888;
}

/* Horizontal handle on hover */
::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555;
}

/** Scollbar */

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-subheader {
  background: radial-gradient(
    circle at 25% 50%,
    rgba(69, 85, 144, 1) 0%,
    rgba(23, 96, 155, 1) 34%,
    rgba(45, 61, 146, 1) 100%
  );
}
