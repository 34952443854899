/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/

.navbar {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  /* position: sticky;
  top: 0;
  z-index: 200; */
}

.nav-menu {
  display: flex;
}

.nav-item {
  padding: 0.6rem;
  color: rgb(0, 0, 0);
  font-size: 1.4rem;
}

.hamburger {
  display: none;
}

.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.5rem;
  width: 0;
  height: 0;
  border-top: 0.4rem solid;
  border-right: 0.4rem solid transparent;
  border-left: 0.4rem solid transparent;
  vertical-align: middle;
}

.dropdown-menu {
  width: max-content;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  display: none;
  border-radius: 2%;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  z-index: 1;
}

.dropdown-menu.active {
  display: block;
}

.dropdown-menu li {
  padding: 0.5rem 1rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.language {
  display: flex;
  align-items: center;
}

.language button {
  padding: 0.2rem 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.language img {
  margin-right: 0.5rem;
  font-size: 1.2rem;
  border-radius: 50%;
  padding: 0.2rem;
  background-color: #f2f2f2;
}

/* Highlight active menu item */
.nav-item.active {
  color: #455590;
  text-decoration: underline solid #455590 10%;
  /* Add any other styles you want for the active menu item */
}

li:hover {
  color: #455590;
}

@media screen and (max-width: 940px) {
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 115px;
    flex-direction: column;
    /* justify-content: center; */
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 0.4s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 0.1rem;
    font-size: 1rem;
    justify-content: center;
  }

  .hamburger {
    display: flex;
  }

  .dropdown {
    display: block;
    text-align: center;
  }

  /* .dropdown-toggle::after {
    display: none;
  } */

  .dropdown-menu {
    position: static;
    transform: none;
    top: auto;
    left: auto;
    display: none;
    width: 100%;
    text-align: center;
  }

  .dropdown-menu.active {
    display: block;
  }

  .dropdown-menu li {
    display: block;
    margin: 0.5rem;
  }
  .language {
    margin-top: 0.5rem;
    flex-direction: row;
  }
  .logo img {
    width: 200px;
  }
}

@media only screen and (min-width: 1279px) {
  .nav-item {
    font-size: 1.2rem;
  }
  .logo img {
    width: 200px;
  }
}
@media only screen and (max-width: 1279px) and (min-width: 1025px) {
  .nav-item {
    font-size: 1rem;
  }
  .logo img {
    width: 160px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 941px) {
  .nav-item {
    font-size: 0.7rem;
  }
  .logo img {
    width: 140px;
  }
  .spanLine {
    padding-left: 1rem;
  }
  .language button {
    padding: 0.1rem 0.2rem;
    font-size: 0.8rem;
  }
  .language img {
    margin-right: 0.1rem;
    width: 1.2rem;
    padding: 0.2rem;
  }
  li.language.nav-item {
    padding: 0rem;
  }
}
